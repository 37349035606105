<template>
  <div>
    <div class="form-container">
      <div class="form-groupx">
        <label for="start-date">Trial Start Date</label>
        <input type="text" id="start-date" :value="TrialstartDate" readonly />
      </div>
      <div class="form-groupx">
        <label for="date">Trial End Date</label>
        <input type="text" id="date" :value="TrialendDate" readonly />
      </div>
      <!-- <div class="form-groupx">
        <label for="temp">Temp</label>
        <input type="text" id="temp" v-model="temp" />
      </div>
      <div class="form-groupx">
        <label for="rh">RH</label>
        <input type="text" id="rh" v-model="rh" />
      </div> -->
    </div>
    <md-card>
      <md-card-content>
        <div class="header-sec"></div>
        <md-card-header data-background-color="green" style="margin: 0px 0px 1%">
          <h4 class="title">BODY WEIGHT PER BIRD(KGs)</h4>
        </md-card-header>
        <div>
          <div class="row align-items-center mb-3">
            <div class="col-auto">
              <ul class="nav nav-pills">
                <li class="nav-item">
                  <a href="#" :class="['nav-link', { active: isActive('view') }]" @click.prevent="setActiveTab('view')">
                    View
                  </a>
                </li>
                <li class="nav-item" v-if="permissionData.add && permissionData.edit && isLatest">
                  <a href="#" :class="['nav-link', { active: isActive('create') }]"
                    @click.prevent="setActiveTab('create')">
                    Create/Edit
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <!-- yakshith (Body weight per bird) -->
        <form @submit.prevent="submit" v-if="activeTab === 'view'">
          <div class="form-group mb-4">
            <div class="row align-items-center">
              <!-- Select Day -->
              <div class="col-md-6">
                <label for="select-day-view" class="form-label">Select Day</label>
                <select id="select-day-view" class="form-control p-2 rounded"
                  style="width: 100%; height: 40px; border: 1px solid black" v-model="selectedDaytoView" required>
                  <option value="" selected disabled>Select Day</option>
                  <option v-for="(day, index) in days" :key="index" :value="day.value">
                    {{ day.label }}
                  </option>
                </select>

              </div>
              <div class="col-md-6" v-if="showDate">
                <label for="show-date" class="form-label">Date</label>
                <input type="text" id="show-date" class="form-control p-2 rounded"
                  style="width: 100%; height: 40px; border: 1px solid black; background-color: #f9f9f9"
                  :value="showDate" readonly />
              </div>


              <!-- <div class="col-md-6">
                <label for="select-trial-group-view" class="form-label"
                  >Select Trial</label
                >
                <select
                  id="select-trial-group-view"
                  class="form-control p-2 rounded"
                  style="width: 100%; height: 40px; border: 1px solid black"
                  v-model="selectedTrialGrouptoView"
                  required
                >
                  <option
                    v-for="(item, index) in trialGroupList"
                    :value="item.id"
                    :key="index"
                  >
                    {{ item.trial_group_id }}
                  </option>
                </select>
              </div> -->
            </div>
          </div>

          <!-- <div
            v-if="load"
            style="display: flex; justify-content: center; height: 100vh"
          >
            <loading-bar></loading-bar>
          </div> -->
          <div v-if="!bodyWtData.length">
            <md-table id="myTable">
              <thead>
                <tr>
                  <th>Pen No.</th>
                  <th>No of Birds</th>
                  <th>Total Female Crate Wt</th>
                  <th>Total Female Bird Wt(Kgs)</th>
                  <th>Avg Female Bird Wt(Kgs)</th>
                  <th>Total Chicks Wt(Kgs)</th>
                  <th>Avg Body Wt/bird(Kgs)</th>
                  <th>Mortality</th>
                  <th>Mortal Bird Wt(Kgs)</th>
                  <th>Leg Weak Bird</th>
                  <th>Leg Weak Bird Wt(Kgs)</th>
                  <th>Vaccine Given</th>
                  <th>Medicine Given</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="loading">
                  <td colspan="100%">
                    <loading-bar></loading-bar>
                  </td>
                </tr>
                <tr v-else>
                  <td colspan="100%" class="text-center">No records found</td>
                </tr>
              </tbody>
            </md-table>
          </div>

          <div v-else>
            <div v-for="(groupData, trialGroupId) in groupedBodyWtData" :key="trialGroupId">
              <md-table id="myTable" class="mt-4">
                <thead>
                  <!-- <tr><th rowspan="3">T{{ trialGroupId }}</th></tr> -->
                  <tr>
                    <th colspan="1" style="background-color: #64B968; color: white">T{{ trialGroupId }}</th>
                  </tr>
                  <tr>
                    <th>Pen No.</th>
                    <th>No of Birds</th>
                    <template v-if="selectedDaytoView < 35">
                      <th v-for="crate in filteredCratesForBirdWt" :key="'bird-wt-' + crate.id">
                        {{ crate.color }} with bird wt
                      </th>
                      <th>Tare wt</th>
                    </template>
                    <template v-else>
                      <!-- Male Section -->
                      <th>Male</th>

                      <th v-for="crate in maleCrates" :key="'male-wt-' + crate.id">
                        <div style="width: 80px">
                          {{ crate.color }} with bird wt
                        </div>
                      </th>



                      <th>
                        <div style="width: 60px">Total Male Crate Wt</div>
                      </th>
                      <th>
                        <div style="width: 60px">Total Male Bird Wt(Kgs)</div>
                      </th>
                      <th>
                        <div style="width: 60px">Avg Male Bird Wt(Kgs)</div>
                      </th>
                      <!-- Female Section -->
                      <th>Female</th>
                      <th v-for="crate in femaleCrates" :key="'female-wt-' + crate.id">
                        <div style="width: 80px">
                          {{ crate.color }} with bird wt
                        </div>
                      </th>
                      <th>
                        <div style="width: 60px">Total Female Crate Wt</div>
                      </th>
                      <th>
                        <div style="width: 60px">Total Female Bird Wt(Kgs)</div>
                      </th>
                      <th>
                        <div style="width: 60px">Avg Female Bird Wt(Kgs)</div>
                      </th>
                    </template>
                    <th>Total Chicks Wt(Kgs)</th>
                    <th>Avg Body Wt/bird(Kgs)</th>
                    <th>Mortality</th>
                    <th>Mortal Bird Wt(Kgs)</th>
                    <th>Leg Weak Bird</th>
                    <th>Leg Weak Bird Wt(Kgs)</th>
                    <template v-if="selectedDaytoView < 35">
                      <th>Vaccine Given</th>
                      <th>Medicine Given</th>
                    </template>

                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(pen, index) in groupData" :key="index">
                    <td class="font-weight-bold">{{ pen.pen_name || "-" }}</td>
                    <td class="font-weight-bold">
                      {{ pen.no_of_birds || "-" }}
                    </td>
                    <template v-if="selectedDaytoView < 35">
                      <!-- Crate Weights for Day < 35 -->
                      <td v-for="crate in filteredCratesForBirdWt" :key="crate.id" class="font-weight-bold">
                        {{
                        pen.bodywtmap.find((item) => item.id === crate.id)
                        ?.crate_with_bird_wt
                        ? formatNumber(
                        pen.bodywtmap.find(
                        (item) => item.id === crate.id
                        ).crate_with_bird_wt
                        )
                        : "-"
                        }}
                      </td>
                      <td class="font-weight-bold">
                        {{ formatNumber(pen.total_crate_wt) || "-" }}
                      </td>
                    </template>
                    <template v-else>
                      <!-- Male Data -->
                      <td class="font-weight-bold">{{ pen.male || "-" }}</td>
                      <td v-for="crate in maleCrates" :key="'male-crate-' + crate.id" class="font-weight-bold">
                        {{
                        findWeightByCrateAndGender(
                        pen.bodywtmalefemale,
                        crate.id,
                        "male"
                        ) || "-"
                        }}
                      </td>
                      <td class="font-weight-bold">
                        {{ formatNumber(pen.male_crate_wt) || "-" }}
                      </td>
                      <td class="font-weight-bold">
                        {{ formatNumber(pen.total_male_bird_wt) || "-" }}
                      </td>
                      <td class="font-weight-bold">
                        {{ formatNumber(pen.avg_male_bird_wt) || "-" }}
                      </td>
                      <!-- Female Data -->
                      <td class="font-weight-bold">{{ pen.female || "-" }}</td>
                      <td v-for="crate in femaleCrates" :key="'female-crate-' + crate.id" class="font-weight-bold">
                        {{
                        findWeightByCrateAndGender(
                        pen.bodywtmalefemale,
                        crate.id,
                        "female"
                        ) || "-"
                        }}
                      </td>
                      <td class="font-weight-bold">
                        {{ formatNumber(pen.female_crate_wt) || "-" }}
                      </td>
                      <td class="font-weight-bold">
                        {{ formatNumber(pen.total_female_bird_wt) || "-" }}
                      </td>
                      <td class="font-weight-bold">
                        {{ formatNumber(pen.avg_female_bird_wt) || "-" }}
                      </td>
                    </template>
                    <td class="font-weight-bold">
                      {{
                      pen.total_bird_wt
                      ? formatNumber(pen.total_bird_wt)
                      : "-"
                      }}
                    </td>
                    <td class="font-weight-bold">
                      {{
                      pen.avg_body_wt_bird
                      ? formatNumber(pen.avg_body_wt_bird)
                      : "-"
                      }}
                    </td>
                    <td class="font-weight-bold">{{ pen.mortality || "-" }}</td>
                    <td class="font-weight-bold">
                      {{
                      pen.mortality_bird_wt
                      ? formatNumber(pen.mortality_bird_wt)
                      : "-"
                      }}
                    </td>
                    <td class="font-weight-bold">
                      {{ pen.leg_weak_bird || "-" }}
                    </td>
                    <td class="font-weight-bold">
                      {{
                      pen.leg_weak_bird_wt
                      ? formatNumber(pen.leg_weak_bird_wt)
                      : "-"
                      }}
                    </td>
                    <template v-if="selectedDaytoView < 35">
                      <td class="font-weight-bold">
                        {{ pen.vaccine_given || "-" }}
                      </td>
                      <td class="font-weight-bold">
                        {{ pen.medicine_given || "-" }}
                      </td>
                    </template>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td style="border: none;"></td>
                    <td class="font-weight-bold" style="background-color: #8E41B4; color: white">{{
                      groupTotals[trialGroupId] || 0 }}</td>
                    <template v-if="selectedDaytoView < 35">
                      <td v-for="crate in filteredCratesForBirdWt" :key="'bird-wt-' + crate.id" style="border: none;">
                      </td>
                      <td style="border: none;"></td>
                    </template>
                    <template v-else>
                      <td style="border: none;"></td>
                      <td v-for="crate in maleCrates" :key="'male-wt-' + crate.id" style="border: none;"></td>
                      <td style="border: none;"> </td>
                      <td style="border: none;"></td>
                      <td class="font-weight-bold" style="background-color: #8E41B4; color: white">
                        {{ groupMaleAvgBodyWt[trialGroupId] ? formatNumber(groupMaleAvgBodyWt[trialGroupId]) : "0" }}
                      </td>
                      <!-- Female Section -->
                      <td style="border: none;"></td>
                      <td v-for="crate in femaleCrates" :key="'female-wt-' + crate.id" style="border: none;">
                      </td>
                      <td style="border: none;"></td>
                      <td style="border: none;"></td>
                      <td class="font-weight-bold" style="background-color: #8E41B4; color: white">
                        {{ groupFemaleAvgBodyWt[trialGroupId] ? formatNumber(groupFemaleAvgBodyWt[trialGroupId]) : "0"
                        }}
                      </td>
                    </template>
                    <td style="border: none;"></td>
                    <td class="font-weight-bold" style="background-color: #8E41B4; color: white">
                      {{ groupAvgBodyWt[trialGroupId] ? formatNumber(groupAvgBodyWt[trialGroupId]) : "0" }}
                    </td>
                  </tr>
                </tfoot>
              </md-table>
            </div>
          </div>
          <!-- <div class="sec-row">
            <div></div>
            <div></div>
            <div>
              <md-button
                class="md-success"
                type="submit"
                @click.prevent="setActiveTab('create')"
              >
                Edit
              </md-button>
            </div>
          </div> -->
        </form>

        <form v-if="activeTab === 'create'">
          <div class="form-group mb-4">
            <div class="row align-items-center">
              <!-- Crates -->
              <div class="col-md-3">
                <span class="md-body-2">Select Crates</span>
                <div class="w-100">
                  <div class="checkbox-select__trigger" :class="{
                      isActive: isDropdownOpen,
                      disabled: !(isSpecificDay || selectedDay >= 35),
                    }" @click="
                      isSpecificDay || selectedDay >= 35
                        ? toggleDropdown()
                        : null
                    " :style="{
                      cursor: !(isSpecificDay || selectedDay >= 35)
                        ? 'not-allowed'
                        : 'pointer',
                    }">
                    <span style="font-size: 12px">Select the applicable</span>
                    <i :class="
                        isDropdownOpen
                          ? 'fa fa-chevron-up'
                          : 'fa fa-chevron-down'
                      "></i>
                  </div>
                  <div id="div" class="checkbox-select__dropdown" v-show="isDropdownOpen">
                    <div class="checkbox-select__col">
                      <div class="checkbox-select__info">
                        {{ selectedCrates.length }} SELECTED
                      </div>
                    </div>
                    <ul id="customScroll" class="checkbox-select__filters-wrapp" data-simplebar-auto-hide="false">
                      <li v-for="(crate, index) in crates" :key="index" style="list-style: none">
                        <div class="checkbox-select__check-wrapp">
                          <input :id="'crate' + index" class="conditions-check" type="checkbox" :value="crate"
                            v-model="selectedCrates" />
                          <label :for="'crate' + index" class="text-capitalize">
                            {{ crate.color }}
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <!-- Select Feed Category -->
              <div class="col-md-3">
                <label for="select-chick-category" class="form-label">Select Feed Category</label>
                <select id="select-chick-category" class="form-control p-2 rounded" :style="{
                    width: '100%',
                    height: '40px',
                    border: '1px solid black',
                  
                    
                  }" v-model="selectedChickCategory" required>
                  <option value="" selected disabled>
                    Select Feed Category
                  </option>
                  <option v-for="(item, index) in chick_category" :value="item.id" :key="index">
                    {{ item.category_name }}
                  </option>
                </select>
              </div>

              <!-- Temperature -->
              <div class="col-md-3">
                <label for="temperature" class="form-label">Temperature</label>
                <input type="text" v-model="temparature" placeholder="Temperature" class="form-control"
                  style="height: 40px; border: 1px solid black" />
              </div>

              <!-- Humidity -->
              <div class="col-md-3">
                <label for="humidity" class="form-label">Humidity</label>
                <input type="text" v-model="tierHumidity" placeholder="Humidity" class="form-control"
                  style="height: 40px; border: 1px solid black" />
              </div>
            </div>
          </div>

          <div v-if="!penList.length">
            <md-table id="myTable">
              <thead>
                <tr>
                  <th>Pen No.</th>
                  <th>No of Birds</th>
                  <th>Total Female Crate Wt</th>
                  <th>Total Female Bird Wt(Kgs)</th>
                  <th>Avg Female Bird Wt(Kgs)</th>
                  <th>Total Chicks Wt(Kgs)</th>
                  <th>Avg Body Wt/bird(Kgs)</th>
                  <th>Mortality</th>
                  <th>Mortal Bird Wt(Kgs)</th>
                  <th>Leg Weak Bird</th>
                  <th>Leg Weak Bird Wt(Kgs)</th>
                  <th>Vaccine Given</th>
                  <th>Medicine Given</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="load">
                  <td colspan="100%">
                    <loading-bar></loading-bar>
                  </td>
                </tr>
                <tr v-else>
                  <td colspan="100%" class="text-center">No Pen data found</td>
                </tr>
              </tbody>
            </md-table>
          </div>

          <md-table v-else id="myTable">
            <thead>
              <tr>
                <th>Select Day and Date</th>
                <th>Trial Group</th>
                <th>Pen No.</th>
                <th>No of Birds</th>
                <template v-if="isSpecificDay">
                  <th style="width: 100px" v-for="crate in selectedCrates" :key="crate.id">
                    {{ crate.color }} with bird wt
                  </th>
                  <th>Tare weight</th>
                </template>
                <template v-if="selectedDay >= 35">
                  <th>Male</th>

                  <th v-for="crate in selectedCrates" :key="crate.id">
                    <div style="width: 100px">
                      {{ crate.color }} with bird wt
                    </div>
                  </th>
                  <th>Tare weight</th>

                  <th>Total Male Bird Wt(Kgs)</th>
                  <th>Avg Male Bird Wt(Kgs)</th>
                  <th>Female</th>
                  <th v-for="crate in selectedCrates" :key="crate.id">
                    <div style="width: 100px">
                      {{ crate.color }} with bird wt
                    </div>
                  </th>
                  <th>Tare weight</th>
                  <th>Total Female Bird Wt(Kgs)</th>
                  <th>Avg Female Bird Wt(Kgs)</th>
                </template>
                <!-- <th>Tare Wt(Kgs)</th> -->
                <template v-if="isSpecificDay || selectedDay >= 35">
                  <th>Total Chicks Wt(Kgs)</th>
                  <th>Avg Body Wt/bird(Kgs)</th>
                </template>
                <template v-if="selectedDay != 1 || dataFetched">
                  <th>Mortality</th>
                  <th>Mortal Bird Wt(Kgs)</th>
                  <th>Leg Weak Bird</th>
                  <th>Leg Weak Bird Wt(Kgs)</th>
                </template>
                <th>Vaccine Given</th>
                <th>Medicine Given</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="!penList.length">
                <td colspan="16" class="text-center">No Pen found</td>
              </tr>

              <tr v-for="(pen, index) in penList" :key="index">
                <template v-if="index === 0">
                  <td :rowspan="penList.length" style="padding-right: 10px;padding-left: 10px;">
                    <select id="select-day" class="form-control p-2 rounded"
                      style="width: 165px;  height: 40px; border: 1px solid black" v-model="selectedDay" required>
                      <option value="" selected disabled>Select Day</option>
                      <option v-for="day in dropdownDays" :key="day.value" :value="day.value">
                        {{ day.label }}
                      </option>
                    </select>
                    <input type="date" class="form-control p-2 mt-2 rounded"
                      style="width: 165px; height: 40px; border: 1px solid black" v-model="selectedDate" required 
                      :disabled="isDateFetched && selectedDate || isSelectedDateFetched" />
                  </td>
                  <td :rowspan="penList.length">
                    <select id="select-trial-group" class="form-control p-2 rounded"
                      style="width: 100%; height: 40px; border: 1px solid black" v-model="selectedTrialGroup" required>
                      <option value="" selected disabled>Select Trial</option>
                      <option v-for="(item, index) in trialGroupList" :value="item.id" :key="index">
                        {{ item.trial_group_id }}
                      </option>
                    </select>
                  </td>
                </template>
                <td class="font-weight-bold">
                  {{ pen.pen_name }}
                </td>
                <td>
                  <input v-model="pen.numberOfBirds" readonly required />
                </td>
                <!-- <td>
                  <input
                    v-model="pen.crateWithBirdWeight"
                    @input="calculateWeights(index)"
                    required
                  />
                </td> -->
                <template v-if="isSpecificDay">
                  <td v-for="crate in selectedCrates" :key="`crate-below-35-${crate.id}`" class="text-center">
                    <div>
                      <input v-model="pen.crateWithBirdWeights[crate.id]" @input="calculateWeights(index)"
                        style="width: 100%" @keydown="navigateInput(index, $event, `crateWithBirdWeights[${crate.id}]`)"
                        :ref="'input-' + index + `-crateWithBirdWeights[${crate.id}]`" required />
                    </div>
                  </td>
                  <td class="font-weight-bold">
                    <input v-model="pen.crateWeight" @input="calculateWeights(index)" style="width: 100%"
                      @keydown="navigateInput(index, $event, 'crateWeight')" :ref="'input-' + index + '-crateWeight'"
                      required />
                  </td>
                </template>
                <template v-if="selectedDay >= 35">
                  <td>
                    <input v-model="pen.maleCount" @input="calculateMaleTotalWeight(index)"
                      @keydown="navigateInput(index, $event, 'maleCount')" :ref="'input-' + index + '-maleCount'" />
                  </td>

                  <td v-for="crate in selectedCrates" :key="`crate-male-${crate.id}`" class="text-center">
                    <div>
                      <input v-model="pen.maleWithBirdWeights[crate.id]" @input="calculateMaleTotalWeight(index)"
                        @keydown="navigateInput(index, $event, `maleWithBirdWeights[${crate.id}]`)"
                        :ref="'input-' + index + `-maleWithBirdWeights[${crate.id}]`" style="width: 100%" required />
                    </div>
                  </td>
                  <input v-model="pen.maleCrateWeight" @input="calculateMaleTotalWeight(index)"
                    @keydown="navigateInput(index, $event, 'maleCrateWeight')"
                    :ref="'input-' + index + '-maleCrateWeight'" style="width: 100%" required />
                  <td class="font-weight-bold">
                    {{ formatNumber(pen.totalMaleWeight) }}
                  </td>

                  <td class="font-weight-bold">
                    {{ formatNumber(pen.avgMaleBirdWt) }}
                  </td>
                  <td>
                    <input v-model="pen.femaleCount" @input="calculateFemaleTotalWeight(index)"
                      @keydown="navigateInput(index, $event, 'femaleCount')" :ref="'input-' + index + '-femaleCount'" />
                  </td>

                  <td v-for="crate in selectedCrates" :key="`crate-female-${crate.id}`" class="text-center">
                    <div>
                      <input v-model="pen.femaleWithBirdWeights[crate.id]" @input="calculateFemaleTotalWeight(index)"
                        @keydown="navigateInput(index, $event, `femaleWithBirdWeights[${crate.id}]`)"
                        :ref="'input-' + index + `-femaleWithBirdWeights[${crate.id}]`" style="width: 100%" required />
                    </div>
                  </td>
                  <input v-model="pen.femaleCrateWeight" @input="calculateFemaleTotalWeight(index)"
                    @keydown="navigateInput(index, $event, 'femaleCrateWeight')"
                    :ref="'input-' + index + '-femaleCrateWeight'" style="width: 100%" required />

                  <td class="font-weight-bold">
                    {{ formatNumber(pen.totalFemaleWeight) }}
                  </td>
                  <td class="font-weight-bold">
                    {{ formatNumber(pen.avgFemaleBirdWt) }}
                  </td>
                </template>
                <!-- <td class="font-weight-bold">
                  {{ formatNumber(pen.selectedCrateWeight) }}
                </td> -->
                <template v-if="isSpecificDay || selectedDay >= 35">
                  <td class="font-weight-bold">
                    {{ formatNumber(pen.totalWeight) }}
                  </td>
                  <td class="font-weight-bold">
                    {{ formatNumber(pen.avgBodyWeight) }}
                  </td>
                </template>
                <template v-if="selectedDay != 1 || dataFetched">
                  <td>
                    <input v-model="pen.mortalityCount" @input="caculateWeightBasedonDay(index)"
                      @keydown="navigateInput(index, $event, 'mortalityCount')"
                      :ref="'input-' + index + '-mortalityCount'" />
                  </td>
                  <td class="font-weight-bold">
                    <input v-model="pen.mortalBirdWeight" :disabled="!pen.mortalityCount"
                      @keydown="navigateInput(index, $event, 'mortalBirdWeight')"
                      :ref="'input-' + index + '-mortalBirdWeight'" />
                  </td>

                  <td>
                    <input v-model="pen.legWeakBirdCount" @input="caculateWeightBasedonDay(index)"
                      @keydown="navigateInput(index, $event, 'legWeakBirdCount')"
                      :ref="'input-' + index + '-legWeakBirdCount'" />
                  </td>
                  <td class="font-weight-bold">
                    <input v-model="pen.legWeakBirdWeight" :disabled="!pen.legWeakBirdCount"
                      @keydown="navigateInput(index, $event, 'legWeakBirdWeight')"
                      :ref="'input-' + index + '-legWeakBirdWeight'" />
                  </td>
                </template>

                <td>
                  <input type="number" v-model="pen.vaccineGiven" />
                </td>
                <td>
                  <input type="number" v-model="pen.medicineGiven" />
                </td>
              </tr>
            </tbody>
          </md-table>

          <div class="sec-row" v-if="penList.length && isEditable">
            <div>
              <md-button type="submit" @click="submitForm" class="md-success" :disabled="isSaving || isUpdating">
                {{
                isSaving
                ? "Saving..."
                : isUpdating
                ? "Updating..."
                : dataFetched
                ? "Update"
                : "Save"
                }}
              </md-button>
            </div>
            <div>
              <md-button v-if="!dataFetched" type="button" @click="clearPenData" class="md-danger">Reset</md-button>
            </div>
          </div>
        </form>
      </md-card-content>
    </md-card>
    <div class="row-layout2">
      <div id="buttons" v-if="!chck && !fcr && !day35">
        <div>
          <!-- <md-button type="submit"  @click="" class="md-success">Next</md-button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingBar from "./LoadingBar.vue";
import moment from "moment";
let id1 = 0;

let id2a = 0;
let id2b = 0;

export default {
  components: {
    LoadingBar,
  },
  data() {
    return {
      isDateFetched : false,
      isEditable: null,
      showDate : null,
      isSelectedDateFetched : false,
      selectedDate: null,
      selectedCrates: [],
      isLatest: false,
      isDropdownOpen: false,
      loading: true,
      trialData: null,
      updateMortalCount: {},
      updateLegWeakCount: {},
      chickCategoryFetched: false,
      noOfBirds: {},
      isSaving: false,
      isUpdating: false,
      temparature: "",
      tierHumidity: "",
      selectedTrialGrouptoView: null,
      selectedDaytoView: "",
      days: [],
      dropdownDays: [],
      bodyWtData: [],
      selectedChickCategory: "",
      chick_category: [],
      activeTab: "view",
      selectedDay: 1,
      load: true,
      dataFetched: false,
      selectedCrateWeight: null,
      trials: [],
      crates: [],
      selectedTrial: null,
      trialGroupList: [],
      selectedTrialGroup: null,
      penList: [],
      chck: false,
      fcr: false,
      day35: false,
      startDate: "",
      currentDate: "",
      temp: "",
      rh: "",
      permissionData: {},
    };
  },
  mounted(){
    this.permissionData = this.$store.getters.getActiveSideBar.permissions[0];
    const selectedTrial = this.$store.getters.getSelectedTrial;
    this.selectedTrial = selectedTrial.id;
    this.trialData = selectedTrial;
    // console.log(this.$store.getters.getSelectedTrial.id);
    this.checkLatestExperiment();
    this.fetchTrialGroup();
  },
  created() {
    this.dropdownDays = [
      ...Array.from({ length: 6 }, (_, i) => ({ label: i + 1, value: i + 1 })),
      { label: "Week 1", value: 7 },
      ...Array.from({ length: 6 }, (_, i) => ({ label: i + 8, value: i + 8 })),
      { label: "Week 2", value: 14 },
      ...Array.from({ length: 6 }, (_, i) => ({
        label: i + 15,
        value: i + 15,
      })),
      { label: "Week 3", value: 21 },
      ...Array.from({ length: 6 }, (_, i) => ({
        label: i + 22,
        value: i + 22,
      })),
      { label: "Week 4", value: 28 },
      ...Array.from({ length: 6 }, (_, i) => ({
        label: i + 29,
        value: i + 29,
      })),
      { label: "Week 5", value: 35 },
    ];

    // this.fetchTrials();
    // this.fetchTrialGroup();
    this.fetchChickCategory();
    this.fetchCrates();
  },
  computed: {
    groupTotals() {
    return Object.entries(this.groupedBodyWtData).reduce(
      (totals, [trialGroupId, pens]) => {
        const groupTotal = pens.reduce(
          (penTotal, pen) => penTotal + (pen.no_of_birds || 0),
          0
        );
        totals[trialGroupId] = groupTotal; 
        return totals;
      },
      {}
    );
  },
  groupAvgBodyWt() {
    return Object.entries(this.groupedBodyWtData).reduce(
      (averages, [trialGroupId, pens]) => {
        const totalAvgBodyWtBird = pens.reduce(
          (sum, pen) => sum + (Number(pen.avg_body_wt_bird) || 0), 
          0
        );

        const avg = pens.length > 0 ? totalAvgBodyWtBird / pens.length : 0; 
        averages[trialGroupId] = avg; 
        return averages;
      },
      {}
    );
  },
  groupMaleAvgBodyWt() {
    return Object.entries(this.groupedBodyWtData).reduce(
      (averages, [trialGroupId, pens]) => {
        const totalMaleAvgBodyWtBird = pens.reduce(
          (sum, pen) => sum + (Number(pen.avg_male_bird_wt) || 0), // Ensure number conversion
          0
        );
        const avg = pens.length > 0 ? totalMaleAvgBodyWtBird / pens.length : 0; // Prevent division by 0
        averages[trialGroupId] = avg; 
        return averages;
      },
      {}
    );
  },
  groupFemaleAvgBodyWt() {
    return Object.entries(this.groupedBodyWtData).reduce(
      (averages, [trialGroupId, pens]) => {
        const totalFemaleAvgBodyWtBird = pens.reduce(
          (sum, pen) => sum + (Number(pen.avg_female_bird_wt) || 0), 
          0
        );
        const avg = pens.length > 0 ? totalFemaleAvgBodyWtBird / pens.length : 0; 
        averages[trialGroupId] = avg;
        return averages;
      },
      {}
    );
  },
    TrialstartDate() {
    return this.trialData && this.trialData.start_date ? this.trialData.start_date : ''; 
  },
  TrialendDate() {
    return this.trialData && this.trialData.end_date ? this.trialData.end_date : ''; 
  },
    isSpecificDay() {
      return [1, 7, 14, 21, 28].includes(this.selectedDay);
    },
    groupedBodyWtData() {
      return this.bodyWtData.reduce((groups, pen) => {
        const trialGroupId = pen.trial_id;
        if (!groups[trialGroupId]) {
          groups[trialGroupId] = [];
        }
        groups[trialGroupId].push(pen);
        return groups;
      }, {});
    },
    filteredCratesForBirdWt() {
      let cratesWithBirdWt = [];
      let crateIds = new Set();
      this.bodyWtData.forEach((pen) => {
        if (pen.bodywtmap && pen.bodywtmap.length > 0) {
          pen.bodywtmap.forEach((crate) => {
            if (!crateIds.has(crate.id)) {
              cratesWithBirdWt.push(crate);
              crateIds.add(crate.id);
            }
          });
        }
      });

      return cratesWithBirdWt;
    },
    maleCrates() {
      return this.filteredCratesForMaleFemale.filter(
        (crate) => crate.gender === "male"
      );
    },
    femaleCrates() {
      return this.filteredCratesForMaleFemale.filter(
        (crate) => crate.gender === "female"
      );
    },
    filteredCratesForMaleFemale() {
      let maleCrates = [];
      let femaleCrates = [];
      let seenMaleCrateIds = new Set();
      let seenFemaleCrateIds = new Set();

      this.bodyWtData.forEach((pen) => {
        if (pen.bodywtmalefemale && pen.bodywtmalefemale.length > 0) {
          pen.bodywtmalefemale.forEach((crate) => {
            const isMale = crate.gender === "male";
            const isFemale = crate.gender === "female";
            if (isMale && !seenMaleCrateIds.has(crate.crate_id)) {
              const matchingCrate = this.crates.find(
                (c) => c.id === crate.crate_id
              );

              if (matchingCrate) {
                maleCrates.push({
                  id: crate.crate_id,
                  gender: crate.gender,
                  weight: crate.weight,
                  color: matchingCrate.color,
                });
              }
              seenMaleCrateIds.add(crate.crate_id);
            }

            if (isFemale && !seenFemaleCrateIds.has(crate.crate_id)) {
              const matchingCrate = this.crates.find(
                (c) => c.id === crate.crate_id
              );
              if (matchingCrate) {
                femaleCrates.push({
                  id: crate.crate_id,
                  gender: crate.gender,
                  weight: crate.weight,
                  color: matchingCrate.color,
                });
              }
              seenFemaleCrateIds.add(crate.crate_id);
            }
          });
        }
      });
      return [...maleCrates, ...femaleCrates];
    },
  },
  methods: {
    navigateInput(index, event, field) {
        if (event.keyCode === 38) {
            if (index > 0) {
                const previousIndex = index - 1;
                this.$refs[`input-${previousIndex}-${field}`][0].focus();
            }
        } else if (event.keyCode === 40) {
            if (index < this.penList.length - 1) {
                const nextIndex = index + 1;
                this.$refs[`input-${nextIndex}-${field}`][0].focus();
            }
        }
    },
    async checkIsEditable() {
      try {
        const response = await axios.get(`indous-research/check-is-editable/${this.selectedTrial}/${this.selectedTrialGroup}`);
        
        this.isEditable = response.data.isEditable;

        if (this.isEditable === false) {
          this.$notify({
            message: "Kindly verify and submit in Corrected feed formula",
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'danger'
          });
        }
      } catch (error) {
        // console.error("Error fetching isEditable status:", error);
      }
  },
    checkLatestExperiment() {
      axios
        .get(`/indous-research/check-latest-experiment`)
        .then((response) => {
          this.isLatest = response.data.is_latest;
        })
        .catch((error) => {
          // console.error("Error checking latest experiment:", error);
        });
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    // api related functions
    fetchTrials() {
      axios
        .get("indous-research/master/trials")
        .then((response) => {
          this.trials = response.data;
          if (this.trials.length > 0) {
            this.selectedTrial = this.trials[0].id;
            this.fetchTrialDetails();
            // this.fetchBodyWtPerBirdData();
          }
        })
        .catch((error) => {
          // console.error("Error fetching trials:", error);
        });
    },
    fetchChickCategory() {
      axios
        .get("indous-research/master/get-chick-category")
        .then((response) => {
          this.chick_category = response.data;
        })
        .catch((error) => {
          // console.error("Error fetching Category:", error);
        });
    },
    fetchCrates() {
      axios
        .get("indous-research/master/get-crate-list")
        .then((response) => {
          this.crates = response.data[0];

          this.crates.forEach((item) => {
            // console.log(item.color);
          });
        })
        .catch((error) => {
          // this.$notify({
          //   message: "Crates not found",
          //   horizontalAlign: "right",
          //   verticalAlign: "top",
          //   type: "danger",
          // });
          // console.error(error);
        });
    },
    fetchTrialDetails() {
      this.selectedTrialGroup = null;
      this.selectedTrialGrouptoView = null;
      axios
        .get(`indous-research/master/trial/${this.selectedTrial}`)
        .then((response) => {
          const data = response.data;
          const [day, month, year] = data.start_date.split("-");
          const startDate = new Date(year, month - 1, day);
          this.startDate = startDate.toLocaleDateString("en-GB");
          this.currentDate = new Date().toLocaleDateString("en-GB");
          this.temp = "27°C";
          this.rh = "60";
          this.fetchTrialGroup();
        })
        .catch((error) => {
          // console.error("Error fetching trial details:", error);
        });
    },
    fetchTrialGroup() {
      this.penList = [];
      this.loading = true;
      this.load = true;
      axios
        .get(
          `indous-research/master/trial-group-list?experiment_info_id=${this.selectedTrial}`
        )
        .then(async (response) => {
          this.trialGroupList = response.data;
          this.selectedTrialGroup = this.trialGroupList[0].id;
          this.selectedTrialGrouptoView = this.trialGroupList[0].id;
          // this.fetchTrialGroupPen();
          this.fetchDays();
          this.loading = false;
          this.load = false;
          // this.showBodyWtData();
          // this.fetchBodyWtPerBirdData();
        })
        .catch((error) => {
          this.$notify({
                                message: error.response.data.message || 'An error occurred',
                                horizontalAlign: 'right',
                                verticalAlign: 'top',
                                type: 'danger'
                            });
          this.clearPenData();
          this.days = [];
          this.selectedDaytoView = "";
          this.bodyWtData = [];
          this.loading = false;
          this.load = false;
          "There was an error fetching the trial group list", error;
        });
    },
    fetchTrialGroupPen() {
      if (this.selectedTrial && this.selectedTrialGroup && this.selectedDay) {
        this.loading = true;
        this.load = true;
        axios
          .get(
            `trial-group-pen-data?trial_number=${this.selectedTrial}&trial_group_id=${this.selectedTrialGroup}&day_week=${this.selectedDay}`
          )
          .then((response) => {
            this.penList = [];
            this.selectedDate = null;
            this.penList = response.data.penList;
            this.dataFetched = false;
            this.loading = false;
            this.load = false;
            this.updateMortalCount = {};
            this.updateLegWeakCount = {};
            this.noOfBirds = response.data.noOfBirds || {};
            if (this.crates.length > 0) {
              this.selectedCrates = [];
              this.selectedCrates = [this.crates[0]];
            }
            // console.log(response.data.start_date);
            if (response.data.start_date) {
              const startDate = moment(response.data.start_date, 'YYYY-MM-DD');
              const selectedDay = parseInt(this.selectedDay, 10); 
              this.selectedDate = startDate.clone().add(selectedDay - 1, 'days').format('YYYY-MM-DD'); // Format as YYYY-MM-DD
              this.isSelectedDateFetched = true; 

            } else {
              this.selectedDate = null; // Handle missing start_date
              this.isSelectedDateFetched = false; 
            }

            const chick_id = response.data.chick_data;
            if (chick_id != null) {
              this.selectedChickCategory = response.data.chick_data.chick_id
                ? response.data.chick_data.chick_id
                : "";
              // this.selectedChickCategory = "";
              this.temparature =
                response.data.chick_data.temp != null
                  ? this.formatNumber(response.data.chick_data.temp)
                  : null;
              this.tierHumidity =
                response.data.chick_data.humidity != null
                  ? this.formatNumber(response.data.chick_data.humidity)
                  : null;
              this.chickCategoryFetched = true;
            } else {
              this.chickCategoryFetched = false;
            }

            this.penList.forEach((pen) => {
              pen.numberOfBirds =
                typeof this.noOfBirds === "object" && this.noOfBirds !== null
                  ? this.noOfBirds[pen.pen_id] !== undefined
                    ? this.noOfBirds[pen.pen_id]
                    : ""
                  : this.noOfBirds;

              pen.avgBodyWeight = "";
              pen.mortalityCount = "";
              pen.mortalBirdWeight = "";
              pen.legWeakBirdCount = "";
              pen.legWeakBirdWeight = "";
              pen.maleCount = "";
              pen.femaleCount = "";
              pen.totalMaleBirdWithTareWt = "";
              pen.totalFemaleBirdWithTareWt = "";
              pen.avgMaleBirdWt = "";
              pen.avgFemaleBirdWt = "";
              pen.vaccineGiven = "";
              pen.medicineGiven = "";
              pen.crateWithBirdWeights = {};
              pen.maleWithBirdWeights = {};
              pen.femaleWithBirdWeights = {};
              pen.totalMaleWeight = "";
              pen.avgMaleBirdWt = "";
              pen.totalFemaleWeight = "";
              pen.avgFemaleBirdWt = "";
              pen.crateWeight = "";
              pen.maleCrateWeight = "";
              pen.femaleCrateWeight = "";
            });
          })
          .catch((error) => {
            // console.log(error);
            this.loading = false;
            this.load = false;
            this.$notify({
              message: "kindly assign the pens in corrected feed formula",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "danger",
            });
          });
      }
    },

    fetchDays() {
      axios
        .post("indous-research/get-days-list", {
          experiment_info_id: this.selectedTrial,
        })
        .then((response) => {
          this.days = response.data;
          this.selectedDaytoView =
            this.days.length > 0 ? this.days[0].value : "";
          // console.log(this.selectedDaytoView);
        })
        .catch((error) => {
          this.days = [];
          this.selectedDaytoView = "";
          // console.log(error);
        });
    },
    // Displaying to update the records
    fetchBodyWtPerBirdData() {
      if (this.selectedTrial && this.selectedTrialGroup && this.selectedDay) {
        this.load = true;
        this.loading = true;
        this.penList = [];
        axios
          .post("indous-research/get-bodywt-data", {
            experiment_info_id: this.selectedTrial,
            trial_group_id: this.selectedTrialGroup,
            age_in_days_or_week: this.selectedDay,
          })
          .then((response) => {
            // this.noOfBirds = {};
            this.load = false;
            this.isSelectedDateFetched = false; 
            const fetchedData = response.data;
            this.selectedCrates = [];
            if(fetchedData[0] && fetchedData[0].date){
              this.selectedDate = moment(fetchedData[0].date).format('YYYY-MM-DD');
              this.isDateFetched = true;
            }else{
              this.selectedDate = null;
              this.isDateFetched = false;
            }
            this.selectedChickCategory = fetchedData[0] && fetchedData[0].chick_id ? fetchedData[0].chick_id : '';
            this.temparature = this.formatNumber(fetchedData[0].temp);
            this.tierHumidity = this.formatNumber(fetchedData[0].humidity);
            this.noOfBirds = {};
            this.updateMortalCount = {};
            this.updateLegWeakCount = {};
            if (fetchedData.length > 0) {
              this.dataFetched = true;
              if (
                typeof this.noOfBirds !== "object" ||
                this.noOfBirds === null
              ) {
                this.noOfBirds = {};
              }
              this.penList = fetchedData.map((fetchedPen, index) => {
                // console.log("Before assignment, noOfBirds:", this.noOfBirds);

                this.noOfBirds[fetchedPen.pen_id] = fetchedPen.no_of_birds;
                this.updateMortalCount[fetchedPen.pen_id] =
                  fetchedPen.mortality;
                // console.log(
                //   "updateMortalCount from fetch ",
                //   this.updateMortalCount
                // );
                this.updateLegWeakCount[fetchedPen.pen_id] =
                  fetchedPen.leg_weak_bird;
                // console.log(this.updateMortalCount);

                // console.log("After assignment, noOfBirds:", this.noOfBirds);
                const crateWeights = {};

                fetchedPen.bodywtmap.forEach((crate) => {
                  crateWeights[crate.pivot.crate_id] = this.formatNumber(
                    crate.pivot.crate_with_bird_wt
                  );

                  if (
                    !this.selectedCrates.some(
                      (selectedCrate) =>
                        selectedCrate.id === crate.pivot.crate_id
                    )
                  ) {
                    const matchedCrate = this.crates.find(
                      (c) => c.id === crate.pivot.crate_id
                    );
                    if (matchedCrate) {
                      this.selectedCrates.push(matchedCrate);
                    } else {
                      this.selectedCrates.push({
                        id: crate.pivot.crate_id,
                        color: crate.color,
                      });
                    }
                  }
                });
                const maleWeights = {};
                const femaleWeights = {};

                fetchedPen.bodywtmalefemale.forEach((weight) => {
                  if (
                    !this.selectedCrates.some(
                      (selectedCrate) => selectedCrate.id === weight.crate_id
                    )
                  ) {
                    const matchedCrate = this.crates.find(
                      (crate) => crate.id === weight.crate_id
                    );

                    if (matchedCrate) {
                      this.selectedCrates.push(matchedCrate);
                    }
                  }

                  if (weight.gender === "male") {
                    maleWeights[weight.crate_id] = this.formatNumber(
                      weight.weight
                    );
                  } else if (weight.gender === "female") {
                    femaleWeights[weight.crate_id] = this.formatNumber(
                      weight.weight
                    );
                  }
                });

                return {
                  pen_name: fetchedPen.pen_name,
                  pen_id: fetchedPen.pen_id,
                  numberOfBirds: fetchedPen.no_of_birds,
                  crateWithBirdWeights: crateWeights,
                  maleWithBirdWeights: maleWeights,
                  femaleWithBirdWeights: femaleWeights,
                  maleCrateWeight: this.formatNumber(fetchedPen.male_crate_wt),
                  femaleCrateWeight: this.formatNumber(
                    fetchedPen.female_crate_wt
                  ),
                  totalWeight: fetchedPen.total_bird_wt,
                  crateWeight: this.formatNumber(fetchedPen.total_crate_wt),
                  avgBodyWeight: fetchedPen.avg_body_wt_bird,
                  mortalityCount: fetchedPen.mortality,
                  mortalBirdWeight: this.formatNumber(
                    fetchedPen.mortality_bird_wt
                  ),
                  legWeakBirdCount: fetchedPen.leg_weak_bird,
                  legWeakBirdWeight: this.formatNumber(
                    fetchedPen.leg_weak_bird_wt
                  ),
                  maleCount: fetchedPen.male,
                  totalMaleWeight: fetchedPen.male_bird_wt,
                  avgMaleBirdWt: fetchedPen.avg_male_wt,
                  femaleCount: fetchedPen.female,
                  totalFemaleWeight: fetchedPen.female_bird_wt,
                  avgFemaleBirdWt: fetchedPen.avg_female_wt,
                  vaccineGiven: fetchedPen.vaccine_given,
                  medicineGiven: fetchedPen.medicine_given,
                };
              });
            } else {
              this.dataFetched = false;
              this.loading = false;
            }
          })
          .catch((error) => {
            // console.log("Error fetching body weight data:", error.response || error);
            this.clearPenData();
            this.load = false;
            this.dataFetched = false;
            this.loading = false;
            this.selectedChickCategory = "";
            this.temparature = "";
            this.tierHumidity = "";
            this.fetchTrialGroupPen();
            // console.error("Failed to fetch pen data:", error);
          });
      }
    },

    showBodyWtData() {
      // this.selectedTrial=null;
      if (
        this.selectedTrial &&
        this.selectedDaytoView
      ) {
        this.bodyWtData = [];
        this.load = true;
        this.loading = true;
        axios
          .post("indous-research/show-bodywt-data", {
            experiment_info_id: this.selectedTrial,
            age_in_days_or_week: this.selectedDaytoView,
          })
          .then((response) => {
            this.bodyWtData = response.data;
            this.showDate = response.data.length > 0 && response.data[0].date
              ? moment(response.data[0].date, 'YYYY-MM-DD').format('DD-MM-YYYY')
              : null;
            this.load = false;
            this.loading = false;
          })
          .catch((error) => {
            this.load = false;
            this.loading = false;
          });
      }
    },
    validateForm() {
      if (
        !this.selectedDay ||
        !this.selectedTrialGroup ||
        !this.selectedChickCategory ||
        this.penList.some((pen) => !pen.pen_name || !pen.numberOfBirds)
      ) {
        this.$notify({
          message: "Please fill all the required fields.",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "danger",
        });
        return false;
      }
      return true;
    },
    submitForm() {
      this.storeData();
    },
    storeData() {
      const dataToStore = {
        age_in_days_or_week: this.selectedDay,
        experiment_info_id: this.selectedTrial,
        trial_group_id: this.selectedTrialGroup,
        chick_id: this.selectedChickCategory,
        temp: this.temparature,
        humidity: this.tierHumidity,
        date: this.selectedDate,

        pens: this.penList.map((pen) => ({
          pen_id: pen.pen_id,
          pen_name: pen.pen_name,
          no_of_birds: pen.numberOfBirds,
          // crate_with_bird_wt: pen.crateWithBirdWeight,
          // crate_id: pen.selectedCrateId,
          //   crateWithBirdWeights: pen.crateWithBirdWeights
          // .map((weight, index) => weight ? { crate_id: this.crates[index].id, weight: weight } : null) // Map to object or null
          // .filter(crateData => crateData),
          //crates weight
          total_crate_wt: pen.crateWeight,
          male_crate_wt: pen.maleCrateWeight,
          female_crate_wt: pen.femaleCrateWeight,

          crateWithBirdWeights: Object.entries(pen.crateWithBirdWeights)
            .map(([key, weight]) => {
              const crateId = parseInt(key);
              const crate = this.crates.find((crate) => crate.id === crateId);
              return crate ? { crate_id: crate.id, weight: weight } : null;
            })
            .filter((crateData) => crateData !== null),
          crateWithMaleBirdWeights: Object.entries(pen.maleWithBirdWeights)
            .map(([key, weight]) => {
              const crateId = parseInt(key);
              const crate = this.crates.find((crate) => crate.id === crateId);
              return crate ? { crate_id: crate.id, weight: weight } : null;
            })
            .filter((crateData) => crateData !== null),
          crateWithFemaleBirdWeights: Object.entries(pen.femaleWithBirdWeights)
            .map(([key, weight]) => {
              const crateId = parseInt(key);
              const crate = this.crates.find((crate) => crate.id === crateId);
              return crate ? { crate_id: crate.id, weight: weight } : null;
            })
            .filter((crateData) => crateData !== null),

          // selectedCrateWeight: pen.selectedCrateWeight,

          total_bird_wt: pen.totalWeight,
          avg_body_wt_bird: pen.avgBodyWeight,
          mortality: pen.mortalityCount,
          mortality_bird_wt: pen.mortalBirdWeight,
          leg_weak_bird: pen.legWeakBirdCount,
          leg_weak_bird_wt: pen.legWeakBirdWeight,
          male: pen.maleCount,
          male_bird_wt: pen.totalMaleWeight,
          avg_male_wt: pen.avgMaleBirdWt,
          female: pen.femaleCount,
          female_bird_wt: pen.totalFemaleWeight,
          avg_female_wt: pen.avgFemaleBirdWt,

          vaccine_given: pen.vaccineGiven,
          medicine_given: pen.medicineGiven,
        })),
      };
      if (this.dataFetched) {
        this.isUpdating = true;
        this.updatePenData(dataToStore);
      } else {
        this.load = true;
        this.isSaving = true;
        axios
          .post("indous-research/body-wt-per-bird", dataToStore)
          .then((response) => {
            this.$notify({
              message: response.data.message,
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "success",
            });
            this.isSaving = false;
            this.load = false;
            this.fetchDays();
            this.showBodyWtData();
            this.fetchBodyWtPerBirdData();

            // console.log("Data stored successfully:", response.data);
          })
          .catch((error) => {
            this.load = false;
            this.isSaving = false;
            this.errors = Object.values(error.response.data.errors).flat();
            // console.log(this.errors);
            this.$notify({
              message:
                error.response.data.error ||
                this.errors[0] ||
                "An error occurred",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "danger",
            });
            // console.error("Error storing data:", error);
          });
      }
    },
    updatePenData(data) {
      this.load = true;
      axios
        .post("indous-research/update-bodywt-data", data)
        .then((response) => {
          // console.log("Records updated successfully:", response.data);
          this.$notify({
            message: response.data.message,
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success",
          });
          this.isUpdating = false;
          this.fetchBodyWtPerBirdData();
          this.load = false;
          this.showBodyWtData();
        })
        .catch((error) => {
          this.load = false;
          this.errors = Object.values(error.response.data.errors).flat();
          // console.log(this.errors);
          this.$notify({
            message:
              error.response.data.error ||
              this.errors[0] ||
              "An error occurred",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "danger",
          });
          this.isUpdating = false;
          // console.error("Failed to update records:", error);
        });
    },
    clearPenData() {
      (this.temparature = ""),
        (this.tierHumidity = ""),
        (this.selectedChickCategory = ""),
        this.penList.forEach((pen, penIndex) => {
          this.$set(this.penList, penIndex, {
            ...pen,
            // numberOfBirds: null,

            selectedCrateWeight: null,
            selectedCrateId: "",
            totalWeight: null,
            avgBodyWeight: null,
            mortalityCount: null,
            mortalBirdWeight: null,
            legWeakBirdCount: null,
            legWeakBirdWeight: null,
            maleCount: null,
            femaleCount: null,
            totalMaleBirdWithTareWt: null,
            totalFemaleBirdWithTareWt: null,
            avgFemaleBirdWt: null,
            avgMaleBirdWt: null,
            vaccineGiven: null,
            medicineGiven: null,
            crateWithBirdWeights: {},
            maleWithBirdWeights: {},
            femaleWithBirdWeights: {},
            totalMaleWeight: "",
            avgMaleBirdWt: "",
            totalFemaleWeight: "",
            avgFemaleBirdWt: "",
          });
        });
    },
    // calculation related function
    // This function formats a number. If the input value is a whole number ex :- 1.000 to 1
    formatNumber(value) {
      if (value !== null && value !== undefined && value !== "") {
        return Number(value) % 1 === 0
          ? Number(value).toFixed(0)
          : Number(value).toFixed(3);
      }
      return "";
    },
    findWeightByCrateAndGender(bodywtmalefemale, crateId, gender) {
      const item = bodywtmalefemale.find(
        (item) => item.crate_id === crateId && item.gender === gender
      );
      return item ? this.formatNumber(item.weight) : null;
    },

    // calculateMaleTotalWeight(penIndex) {
    //   const pen = this.penList[penIndex];
    //   let totalBirdWeight = 0;
    //   // console.log("numner of birds");

    //   // console.log('Current pen data:', pen);
    //   Object.entries(pen.maleWithBirdWeights).forEach(
    //     ([crateId, crateWithBirdWeight]) => {
    //       if (crateWithBirdWeight) {
    //         const crate = this.crates.find(
    //           (crate) => crate.id === parseInt(crateId)
    //         );
    //         if (crate) {
    //           const crateWeight = crate.weight || 0;
    //           const birdWeight = crateWithBirdWeight - crateWeight;
    //           totalBirdWeight += birdWeight;
    //         }
    //       }
    //     }
    //   );
    //   // console.log(totalBirdWeight);
    //   pen.totalMaleWeight = totalBirdWeight ? totalBirdWeight.toFixed(3) : null;
    //   if (pen.maleCount > 0 && pen.totalMaleWeight) {
    //     pen.avgMaleBirdWt = (totalBirdWeight / pen.maleCount).toFixed(3);
    //     // console.log(pen.avgMaleBirdWt);
    //   } else {
    //     pen.avgMaleBirdWt = null;
    //   }
    //   this.$set(this.penList, penIndex, { ...pen });
    //   this.calculateTotal(penIndex);
    // },
    calculateMaleTotalWeight(penIndex) {
      const pen = this.penList[penIndex];
      if (
        pen.maleCrateWeight &&
        Object.values(pen.maleWithBirdWeights).some((weight) => weight)
      ) {
        const totalWeightWithBirds = Object.values(
          pen.maleWithBirdWeights
        ).reduce((sum, weight) => sum + parseFloat(weight || 0), 0);
        const crateWeight = parseFloat(pen.maleCrateWeight || 0);
        const totalBirdWeight = totalWeightWithBirds - crateWeight;
        pen.totalMaleWeight = totalBirdWeight
          ? totalBirdWeight.toFixed(3)
          : null;
        if (pen.maleCount > 0) {
          pen.avgMaleBirdWt = (totalBirdWeight / pen.maleCount).toFixed(3);
        } else {
          pen.avgMaleBirdWt = null;
        }
      } else {
        pen.totalMaleWeight = null;
        pen.avgMaleBirdWt = null;
      }
      this.$set(this.penList, penIndex, { ...pen });
      this.calculateTotal(penIndex);
    },
    calculateFemaleTotalWeight(penIndex) {
      const pen = this.penList[penIndex];
      if (
        pen.femaleCrateWeight &&
        Object.values(pen.femaleWithBirdWeights).some((weight) => weight)
      ) {
        const totalWeightWithBirds = Object.values(
          pen.femaleWithBirdWeights
        ).reduce((sum, weight) => sum + parseFloat(weight || 0), 0);
        const crateWeight = parseFloat(pen.femaleCrateWeight || 0);
        const totalBirdWeight = totalWeightWithBirds - crateWeight;
        pen.totalFemaleWeight = totalBirdWeight
          ? totalBirdWeight.toFixed(3)
          : null;
        if (pen.femaleCount > 0) {
          pen.avgFemaleBirdWt = (totalBirdWeight / pen.femaleCount).toFixed(3);
        } else {
          pen.avgFemaleBirdWt = null;
        }
      } else {
        pen.totalFemaleWeight = null;
        pen.avgFemaleBirdWt = null;
      }
      this.$set(this.penList, penIndex, { ...pen });
      this.calculateTotal(penIndex);
    },

    // calculateFemaleTotalWeight(penIndex) {
    //   const pen = this.penList[penIndex];
    //   let totalBirdWeight = 0;
    //   Object.entries(pen.femaleWithBirdWeights).forEach(
    //     ([crateId, crateWithBirdWeight]) => {
    //       if (crateWithBirdWeight) {
    //         const crate = this.crates.find(
    //           (crate) => crate.id === parseInt(crateId)
    //         );
    //         if (crate) {
    //           const crateWeight = crate.weight || 0;
    //           const birdWeight = crateWithBirdWeight - crateWeight;
    //           totalBirdWeight += birdWeight;
    //         }
    //       }
    //     }
    //   );
    //   pen.totalFemaleWeight = totalBirdWeight
    //     ? totalBirdWeight.toFixed(3)
    //     : null;
    //   if (pen.femaleCount > 0 && pen.totalFemaleWeight > 0) {
    //     pen.avgFemaleBirdWt = (totalBirdWeight / pen.femaleCount).toFixed(3);
    //   } else {
    //     pen.avgFemaleBirdWt = null;
    //   }
    //   this.$set(this.penList, penIndex, { ...pen });
    //   this.calculateTotal(penIndex);
    // },
    calculateTotal(penIndex) {
      const pen = this.penList[penIndex];
      const totalMaleWeight = parseFloat(pen.totalMaleWeight) || 0;
      const totalFemaleWeight = parseFloat(pen.totalFemaleWeight) || 0;
      // console.log(totalMaleWeight,totalFemaleWeight );

      if (totalMaleWeight > 0 && totalFemaleWeight > 0) {
        pen.totalWeight = (totalMaleWeight + totalFemaleWeight).toFixed(3);
      } else {
        pen.totalWeight = null;
      }

      if (pen.maleCount > 0 && pen.femaleCount > 0 && pen.totalWeight) {
        pen.avgBodyWeight = (
          parseFloat(pen.totalWeight) / pen.numberOfBirds
        ).toFixed(3);
      } else {
        pen.avgBodyWeight = null;
      }
      this.$set(this.penList, penIndex, { ...pen });
      this.updateMortalBirdWeight(penIndex);
      this.updateLegWeakBirdCount(penIndex);
    },
    // updateMortalBirdWeight(index) {
    //   const pen = { ...this.penList[index] };
    //   const originalNumberOfBirds = this.noOfBirds[pen.pen_id];

    //   if (originalNumberOfBirds !== undefined) {
    //     if (
    //       pen.numberOfBirds === "" ||
    //       pen.numberOfBirds === null ||
    //       pen.numberOfBirds == originalNumberOfBirds
    //     ) {
    //       pen.mortalityCount = null;
    //     } else {
    //       pen.mortalityCount = originalNumberOfBirds - pen.numberOfBirds;
    //     }
    //   } else {
    //     pen.mortalityCount = null;
    //   }

    //   this.$set(this.penList, index, pen);
    // },
    updateMortalBirdWeight(index) {
      const pen = { ...this.penList[index] };
      // const originalNumberOfBirds = this.noOfBirds[pen.pen_id];
      // const originalNumberOfBirds = typeof this.noOfBirds === 'object' ?  this.noOfBirds[pen.pen_id] : this.noOfBirds;
      const originalNumberOfBirds = 
  (this.noOfBirds && typeof this.noOfBirds === 'object' && !Array.isArray(this.noOfBirds))
    ? this.noOfBirds[pen.pen_id]
    : this.noOfBirds;
      const updateMortalCount = this.updateMortalCount[pen.pen_id];
      const dataFetched = this.dataFetched;

      if (originalNumberOfBirds !== undefined) {
        if (pen.mortalityCount === "" || pen.mortalityCount === null) {
          // pen.numberOfBirds = originalNumberOfBirds;
          pen.numberOfBirds = originalNumberOfBirds + (updateMortalCount || 0);
          pen.mortalBirdWeight = null;
        } else {
          if (updateMortalCount !== null && dataFetched) {
            pen.numberOfBirds =
              originalNumberOfBirds + updateMortalCount - pen.mortalityCount;
          } else {
            pen.numberOfBirds = originalNumberOfBirds - pen.mortalityCount;
          }
        }
      } else {
        // pen.numberOfBirds = null;
      }

      this.$set(this.penList, index, pen);
    },

    updateLegWeakBirdCount(index) {
      const pen = { ...this.penList[index] };
      const latestNumberOfBirds = pen.numberOfBirds;
      const updateLegWeakCount = this.updateLegWeakCount[pen.pen_id];
      const dataFetched = this.dataFetched;
      // console.log(latestNumberOfBirds);

      if (latestNumberOfBirds !== undefined) {
        if (pen.legWeakBirdCount === "" || pen.legWeakBirdCount === null) {
          // pen.numberOfBirds = latestNumberOfBirds;
          pen.numberOfBirds = latestNumberOfBirds + (updateLegWeakCount || 0);
          pen.legWeakBirdWeight = null;
        } else {
          if (updateLegWeakCount !== null && dataFetched) {
            pen.numberOfBirds =
              latestNumberOfBirds - (pen.legWeakBirdCount - updateLegWeakCount);
          } else {
            pen.numberOfBirds = latestNumberOfBirds - pen.legWeakBirdCount;
            //  console.log(latestNumberOfBirds ,pen.legWeakBirdCount );
          }
        }
      } else {
        pen.legWeakBirdCount = null;
      }

      this.$set(this.penList, index, pen);
    },

    caculateWeightBasedonDay(penIndex) {
      this.updateMortalBirdWeight(penIndex);
      this.updateLegWeakBirdCount(penIndex);
      if (this.selectedDay >= 35) {
        this.calculateTotal(penIndex);
      } else {
        this.calculateWeights(penIndex);
      }
    },
    //if day < 35

    calculateWeights(penIndex) {
      const pen = this.penList[penIndex];
      const hasValidWeights =
        pen.crateWeight &&
        pen.crateWithBirdWeights &&
        Object.values(pen.crateWithBirdWeights).some(
          (weight) => weight !== null && weight !== ""
        );
      if (hasValidWeights) {
        const totalWeightWithBirds = Object.values(
          pen.crateWithBirdWeights
        ).reduce((sum, weight) => sum + parseFloat(weight || 0), 0);
        const crateWeight = parseFloat(pen.crateWeight || 0);
        const totalBirdWeight = totalWeightWithBirds - crateWeight;
        pen.totalWeight = totalBirdWeight.toFixed(3);

        if (pen.numberOfBirds > 0) {
          pen.avgBodyWeight = (totalBirdWeight / pen.numberOfBirds).toFixed(3);
        } else {
          pen.avgBodyWeight = "0.000";
        }
      } else {
        pen.totalWeight = null;
        pen.avgBodyWeight = null;
      }
      this.$set(this.penList, penIndex, { ...pen });
    },

    calculateAvgMaleBirdWt(index) {
      const pen = { ...this.penList[index] };
      if (
        pen.maleCount &&
        pen.totalMaleBirdWithTareWt &&
        pen.selectedCrateWeight
      ) {
        const totalMaleBirdWeight =
          pen.totalMaleBirdWithTareWt - pen.selectedCrateWeight;
        pen.avgMaleBirdWt = (totalMaleBirdWeight / pen.maleCount).toFixed(3);
      } else {
        pen.avgMaleBirdWt = null;
      }
      this.$set(this.penList, index, pen);
    },
    calculateAvgFemaleBirdWt(index) {
      const pen = { ...this.penList[index] };
      if (
        pen.femaleCount &&
        pen.totalFemaleBirdWithTareWt &&
        pen.selectedCrateWeight
      ) {
        const totalFemaleBirdWeight =
          pen.totalFemaleBirdWithTareWt - pen.selectedCrateWeight;
        pen.avgFemaleBirdWt = (totalFemaleBirdWeight / pen.femaleCount).toFixed(
          3
        );
      } else {
        pen.avgFemaleBirdWt = null;
      }
      this.$set(this.penList, index, pen);
    },
    isActive(tab) {
      return this.activeTab === tab;
    },
    setActiveTab(tab) {
      this.activeTab = tab;
    },
    isActiveMain(tab) {
      return this.activeTaba === tab;
    },
    setActiveTabMain(tab) {
      this.activeTaba = tab;
    },
  },
  watch: {
    penList: {
      deep: true, // To watch nested properties within penList
      handler(newPenList) {
        newPenList.forEach((pen, index) => {
          if (pen.totalWeight < 0) {
            this.$notify({
              message: "Total weight and avg cannot be negative",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "danger",
            });
          }
          if (pen.totalMaleWeight < 0) {
            this.$notify({
              message: "Total male weight and avg cannot be negative",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "danger",
            });
          }
          if (pen.totalFemaleWeight < 0) {
            this.$notify({
              message: "Total female weight and avg cannot be negative",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "danger",
            });
          }

          // this.updateMortalityCount(index);
        });
      },
    },
    selectedTrial(newVal, oldVal) {
      this.fetchBodyWtPerBirdData();
      this.showBodyWtData();
      // this.checkIsEditable();  
      this.$emit("update:selectedTrial", this.selectedTrial);
    },
    // selectedChickCategory(newVal, oldVal) {
    //   this.fetchBodyWtPerBirdData();
    // },
    selectedDaytoView(newVal, oldVal) {
      this.showBodyWtData();
    },
    selectedTrialGrouptoView(newVal, oldVal) {
      this.showBodyWtData();
    },
    selectedTrialGroup(newVal, oldVal) {
      this.fetchBodyWtPerBirdData();
      this.checkIsEditable();
    },
    selectedDay(newVal, oldVal) {
      this.fetchBodyWtPerBirdData();
    },
  },
};
</script>
<style scoped>
.crate-header {
  width: 200px;
}
.readonly-select {
    pointer-events: none; 
    background-color: #fff; 
    color: #000; 
    border: 1px solid #ced4da; 
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.2;
    appearance: none; 
    -moz-appearance: none;
    -webkit-appearance: none;
  }
  .readonly-select::after {
    content: "▼";
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none; 
  }
  table input {
  width: 100%;
  box-sizing: border-box;
  height: 40px;
}
</style>